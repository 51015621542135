
import moment, { Moment} from 'moment';
import { Options, Vue } from 'vue-class-component';
import { Provide } from 'vue-property-decorator';
import { message } from 'ant-design-vue/es';
import { throttle } from '../../untils/until';
import { shipperList,Greeter,theManagementListFbfjbxx } from '../../api/platform';
interface FormState {
  nsrmc: string;
  nsrsbh: string;
  hy: string;
  zgswskfj: string;
  nsrzt: string;
  zcdz: string;
  zcdzxzqh: string;
  zgswjg: string;
  fddbr: string;
  scjydzxzqh: string;
  jdxz: string;
  scjydz: string;
}
@Options({
  components: {
  },
})
export default class OrdInfo extends Vue {
   @Provide() columns: any
   rules: any = {
      nsrmc: [
        { required: true, message: '请输入企业名称', trigger: 'blur' },
      ],
      nsrsbh: [
        { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
      ],
      hy: [
        { required: true, message: '请选择行业', trigger: 'blur' },
      ],
      fddbr: [
         { required: true, message: '请输入法定代表人', trigger: 'blur' },
      ],
      qyyddh: [
         { required: true, message: '请输入企业手机号', trigger: 'blur' },
          { pattern: /^1[3|4|5|7|8]\d{9}$/, message: '请输入合法的手机号',trigger: 'blur' },
      ],
      zgswjg: [
        { required: true, message: '请输入主管税务机关', trigger: 'blur' },
      ],
      nsrlx: [
        { required: true, message: '请选择企业类型', trigger: 'blur' },
      ],
      zczb: [
        { required: true, message: '请输入注册资本', trigger: 'blur' },
         {
        pattern: /^\d+(\.\d+)?$/,
        message: '注册资本只能位数字',
        trigger: 'blur'
      }
      ],
      slrq: [
        { required: true, message: '请输入成立日期', trigger: 'blur' },
      ],
      gsdz:[
        { required: true, message: '请输入公司地址', trigger: 'blur' },
      ],
      xxdz:[
        { required: true, message: '请输入详细地址', trigger: 'blur' },
      ],
      ssdq:[
        { required: true, message: '请输入所属地区', trigger: 'blur' },
      ],
      jyfw: [
        { required: true, message: '请输入经营范围', trigger: 'blur' },
      ],
      ptzcsj: [
        { required: true, message: '请输入注册时间', trigger: 'blur' },
      ],
       yyzzfjPic: [{required: true, message: '请上传营业执照'}],
   }  
   formState: {
      nsrmc: string;
      nsrsbh: string;
      hy: string;
      zgswskfj: string;
      nsrzt: string;
      zcdz: string;
      zcdzxzqh: string;
      zgswjg: string;
      fddbr: string;
      scjydzxzqh: string;
      jdxz: string;
      scjydz: string;
      bsrsfzjzl: string;
      nsrlx: string;
      gjdq: string;
      fbfuuid:string,
      ptzcsj:string,
      slrq:string,
      ssdq:string,
      yyzzfjPic:string
      ssdqName:string,
      zgswjgName:string
   } = {
      nsrmc:"",
      nsrsbh: "",
      hy: "",
      zgswskfj: "",
      nsrzt: "正常",
      zcdz: "",
      zcdzxzqh: "",
      zgswjg: "",
      fddbr: "",
      scjydzxzqh:"",
      jdxz: "",
      scjydz: "",
      bsrsfzjzl: "",
      nsrlx: "小规模",
      gjdq: "中华人民共和国(156)",
      fbfuuid:"",
      ptzcsj:"",
      slrq:"",
      ssdq:"",
      yyzzfjPic:"",
      ssdqName:"",
      zgswjgName:""
  }
  imageUrl: string = "";
  formRef:any = ""
  url: string = process.env.VUE_APP_BASEURL;
  location:any = localStorage.tsToken;
  //验证数据
  smrzfjPicBoole: boolean = false;
  verifyStatedOptions:any = [
    {name:"一般人",id: "0"},
    {name:"小规模",id: "1"},
  ];
  taxOffice:any = [];
  eachDistrict:any = [];
  frontPhoto:any = [];
   // 获取code
   handleChange(value: string):void {
     this.formState.ssdq = value
   }
   // zgswjg
   handleChange1(value: string):void {
     this.formState.zgswjg = value
   }

  // 获取公告的方法
  handleSearchCertificate(val:any): void{
    let handCommon= new Greeter(val,3);
    handCommon.greet().then((res:any)=>{
      this.taxOffice = res.data.list
    })
  }

  backList() {
    sessionStorage.setItem('isManageDetail', 'true')
    this.$router.back()
  }

  RemoveLImg(file: File): void {
    this.formState.yyzzfjPic = ''
  }
  // 获取地区
  handleSearchEachDistrict(val:any): void{
    let handCommon= new Greeter(val,2);
    handCommon.greet().then((res:any)=>{
      this.eachDistrict = res.data.list
    })
  }

  onChange(value: Moment[], dateString: string):void {
     this.formState.ptzcsj = dateString
  }
   onChange1(value: Moment[], dateString: string):void {
     this.formState.slrq = dateString
  }
  
  saveTheData():void{
     this.formRef = this.$refs.formRef
     this.formRef.validate().then(() => {
     this.formState.gjdq = "156"
     theManagementListFbfjbxx(this.formState).then((res: any) =>{
       if (res.code ==200) {
         message.success(res.message);
         this.$router.push('/management')
       }
    })
    })
  }

  save():void {
    throttle(this.saveTheData,500)()
  }

  mounted() {
    shipperList(this.$router.currentRoute.value.query.query).then((res: any) =>{
      this.formState = res.data
      this.formState.ssdqName = res.data.ssdqName+"("+ res.data.ssdq+")";
      this.formState.zgswjgName = res.data.zgswjgName+"("+ res.data.zgswjg+")";
      this.formState.gjdq ="中华人民共和国(156)"
      this.frontPhoto =[{
          uid: "-1",
          name: 'image.png',
          status: 'done',
          url:res.data.yyzzfjPic
      }]
    })
  }
}
